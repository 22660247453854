<template>
  <div>
    <div class="content" v-if="netError">
      <p style="color: red">网络请求超时，请刷新页面重试</p>
    </div>
    <el-table :data="tableData" class="table">
      <el-table-column type="index" width="50"></el-table-column>
      <el-table-column label="照片信息">
        <template slot-scope="scope">
          <!-- <img :src="scope.row.img" alt /> -->
          <el-image :src="scope.row.img">
            <div slot="error" class="image-slot">
              <img :src="scope.row.errorIcon" alt />
            </div>
          </el-image>
          <div class="fr">
            <p class="code">{{ scope.row.id }}</p>
            <p class="label">
              {{ scope.row.imgType }}
              <span class="size">{{ scope.row.imgSize }}</span>
            </p>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="下载费用">
        <template slot-scope="scope">
          <div class="price" v-if="scope.row.complete">
            <p class="font-red">余额：{{ scope.row.price }}元</p>
          </div>
          <div class="price" v-else>
            <p class="font-blue" v-if="scope.row.type && !scope.row.isBuy">
              单价：{{ scope.row.price }} 元
              <span v-if="scope.row.orgPrice"
                ><del>(原价：{{ scope.row.orgPrice }} 元)</del></span
              >
            </p>
            <p class="font-blue" v-else>{{ scope.row.msg }}</p>
            <el-button
              type="danger"
              round
              v-if="scope.row.saleType !== null && scope.row.saleType !== ''"
              size="mini"
              >{{ scope.row.saleType }}</el-button
            >
          </div>
        </template>
      </el-table-column>
      <el-table-column label="图片格式">
        <template slot-scope="scope" v-if="scope.row.showType">
          <div v-if="!scope.row.isVideo">
            <el-select
              v-model="scope.row.value"
              v-if="scope.row.type"
              :disabled="scope.row.disabled"
              placeholder="请选择"
            >
              <el-option
                v-for="item in scope.row.options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div v-else>
            <el-radio-group
              v-model="scope.row.videoLabel"
              v-if="scope.row.type"
              :disabled="scope.row.videoDisabled"
            >
              <el-radio
                v-for="item in scope.row.videoOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
                @change="
                  changePrice(scope.$index, tableData, item.label, item.value)
                "
              ></el-radio>
            </el-radio-group>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="操作状态">
        <template slot-scope="scope">
          <div class="processBar" v-if="scope.row.loading"></div>
          <div v-else-if="!scope.row.loading && scope.row.complete">
            <a rel="nofollow" :href="scope.row.downurl" target="_blank"
              ><el-button
                type="primary"
                size="small"
                style="margin-left: 5px; margin-top: 5px"
                >点击下载</el-button
              ></a
            >
            <a rel="nofollow" :href="scope.row.downurl2" target="_blank"
              ><el-button
                type="success"
                size="small"
                style="margin-left: 5px; margin-top: 5px"
                >备用下载</el-button
              ></a
            >
            <a rel="nofollow" :href="scope.row.downurl3" target="_blank"
              ><el-button
                type="danger"
                size="small"
                style="margin-left: 5px; margin-top: 5px"
                >海外下载</el-button
              ></a
            >
          </div>
          <div v-else-if="!scope.row.loading && scope.row.error">
            <p style="color: red">{{ scope.row.msg }}</p>
            <el-button
              v-if="scope.row.showButton"
              type="danger"
              size="small"
              @click="changeV3()"
              style=" text-align: center;"
              >点击前往V3通道（价格稍贵）</el-button
            >
          </div>
          <div v-else>
            <el-button
              type="text"
              size="middle"
              @click="deleteRow(scope.$index, tableData)"
              ><i class="el-icon-close"></i
            ></el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :page-size="pageSize"
      :pager-count="11"
      layout="total, sizes, prev, pager, next"
      :total="tableData.length"
      v-if="tableData.length > 100"
    ></el-pagination>
    <div class="btn" v-if="!sureflag">
      <el-button
        type="primary"
        @click="sureDownload"
        plain
        icon="el-icon-edit"
        :disabled="downflag"
        >确认下载</el-button
      >
    </div>
    <div class="btn2" v-else>
      <div class="el-row">
        <el-button
          type="primary"
          @mousedown.native="batchDownload()"
          plain
          icon="el-icon-download"
          >批量下载</el-button
        >
        <el-button
          type="primary"
          @mousedown.native="batchCopy($event)"
          plain
          icon="el-icon-edit-outline"
          style="margin-left: 1%"
          >一键复制下载链接</el-button
        >
      </div>
      <div class="el-row">
        <el-button
          type="primary"
          @mousedown.native="batchDownload1()"
          plain
          icon="el-icon-download"
          >批量备用下载</el-button
        >
        <el-button
          type="primary"
          @mousedown.native="batchCopy1($event)"
          plain
          icon="el-icon-edit-outline"
          style="margin-left: 1%"
          >一键复制备用下载链接</el-button
        >
      </div>
      <div class="el-row">
        <el-button
          type="primary"
          @mousedown.native="batchDownload2()"
          plain
          icon="el-icon-download"
          >批量海外下载</el-button
        >
        <el-button
          type="primary"
          @mousedown.native="batchCopy2($event)"
          plain
          icon="el-icon-edit-outline"
          style="margin-left: 1%"
          >一键复制海外下载链接</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { hasBlance } from "../../../api/downLoad";
import { buyWait, check } from "../../../api/downLoad";
import { getSessionUser } from "../../../api/auth";
import { MessageBox } from "element-ui";
import { message } from "@/utils/resetMessage";
import Clipboard from "clipboard";

export const downloadFile = (url) => {
  const iframe = document.createElement("iframe");
  iframe.style.display = "none"; // 防止影响页面
  iframe.style.height = 0; // 防止影响页面
  iframe.src = url;
  document.body.appendChild(iframe); // 这一行必须，iframe挂在到dom树上才会发请求
  // 5分钟之后删除（onload方法对于下载链接不起作用，就先抠脚一下吧）
  setTimeout(() => {
    iframe.remove();
  }, 1000);
};

export default {
  props: ["data", "sureflag", "downflag", "clearflag", "netError"],
  data() {
    return {
      currentPage: 1,
      pageSize: 10,
      urlArray: [], //批量下载
      urlArray1: [], //批量备用下载
      urlArray2: [], //批量海外下载
      errorTimes: 0, //查询错误次数
      rightTimes: 0, //查询正确次数
      currentIndex: 0, //当前列表序号
      timer1: null, //定时器
    };
  },
  watch: {
    clearflag: {
      handler(val) {
        if (val) this.currentIndex = 0;
      },
      deep: true,
    },
  },
  computed: {
    tableData() {
      return this.data;
    },
  },
  mounted() {
    this.$EventBus.$on("nextId", (data) => {
      //将A组件传递过来的参数data赋值给msgB
      if (data >= 0) {
        //window.clearInterval();
        this.$emit("clearTimer");
        this.errorTimes = 0;
        this.rightTimes = 0;
        this.currentIndex = data + 1;
        //更新余额
        getSessionUser({})
          .then((result) => {
            this.$store.dispatch("setUserInfo", result);
          })
          .catch((error) => {});
        if (this.currentIndex < this.tableData.length) {
          let item = this.tableData[this.currentIndex];
          if (!item.checking) {
            item.checking = true;
            //未下载完成的进行下载
            this.downloadOne(this.currentIndex);
          } else {
            let checkdata = {
              imageID: item.id,
              webType: item.webType,
            };
            this.checkStatus(checkdata, this.currentIndex);
          }
        } else {
          this.$emit("onSureDown", true);
          this.$emit("onDownload", false);
        }
      }
    });
  },
  beforeDestroy() {
    this.$EventBus.$off("nextId");
    if (this.timer1) {
      clearTimeout(this.timer1);
      this.timer1 = null;
    }
  },
  methods: {
    popSuccess() {
      if (document.getElementsByClassName("el-message").length > 0) return;
      this.$message({
        message: "下载成功",
        type: "success",
        showClose: true,
        duration: 4000,
        offset: 80,
      });
    },
    changeV3() {
      this.$emit("chanegV3", 1);
    },
    //普通下载
    ordinaryDownload(index, row) {
      //window.open(row.downurl,"_self");
      downloadFile(row.downurl);
    },
    //备用下载
    standbyDownload(index, row) {
      //window.open(row.downurl2,"_self");
      downloadFile(row.downurl2);
    },
    //迅雷下载
    thunderDownload(index, row) {
      console.log("thunderDownload: " + row.downurl);
      //this.thunderHref=row.downurl;
      console.log("thunderDownload: " + index);
    },
    //确认下载
    sureDownload() {
      this.urlArray = [];
      this.urlArray1 = [];
      this.urlArray2 = [];
      var counts = 0;
      var offsetCounts = 0;
      this.currentIndex = 0;
      this.tableData.forEach((item) => {
        if (item.type) {
          counts++;
          if (item.isOffset) {
            offsetCounts++;
          }
        }
      });
      if (counts > 0) {
        if (offsetCounts > 0) {
          MessageBox.confirm(
            "您此次下载含ss高级图，高级图费用较特殊，请您确认是否下载？",
            "温馨提示",
            {
              confirmButtonText: "继续下载",
              cancelButtonText: "取消下载",
              type: "warning",
            }
          )
            .then(() => {
              this.$emit("onSureDown", false);
              this.$emit("onDownload", true);
              //判断是否够余额
              this.downloadOne(this.currentIndex);
            })
            .catch(() => {});
        } else {
          this.$emit("onSureDown", false);
          this.$emit("onDownload", true);
          //判断是否够余额
          this.downloadOne(this.currentIndex);
        }
      } else {
        message.success({
          message: "请先输入需要下载的ID",
          duration: 4000,
          offset: 80,
        });
      }
    },
    //下载方法
    downloadOne(index) {
      let item = this.tableData[index];
      if (item.type) {
        //正常才搜索
        item.loading = true;
        let dataform = {
          imageIDs: item.id,
          webType: item.webType,
        };
        hasBlance(dataform)
          .then((result) => {
            if (result) {
              //如果余额足够，则开始获取下载链接
              let buydata = {
                imageID: item.id,
                webType: item.webType,
                format: item.value,
                isCos: item.isCos,
              };
              buyWait(buydata)
                .then((result) => {
                  let checkdata = {
                    imageID: item.id,
                    webType: item.webType,
                  };
                  this.checkStatus(checkdata, index);
                })
                .catch(() => {
                  buyWait(buydata)
                    .then((result) => {
                      let checkdata = {
                        imageID: item.id,
                        webType: item.webType,
                      };
                      this.checkStatus(checkdata, index);
                    })
                    .catch(() => {
                      item.loading = false;
                      item.msg = "该ID下载失败，请联系管理员";
                      item.error = true;
                      this.$EventBus.$emit("nextId", index); //下一个ID
                    });
                });
            } else {
              message.error({
                message: "您的余额已不足，请充值",
                duration: 3000,
                offset: 100,
              });
              item.loading = false;
              item.msg = "下载失败，余额不足";
              item.error = true;
              this.$emit("onSureDown", true);
              this.$emit("onDownload", false);
            }
          })
          .catch((error) => {
            if (error.message.indexOf("账号余额不足") > -1) {
              item.msg = "下载失败，余额不足";
              item.loading = false;
              item.error = true;
              this.$emit("onSureDown", true);
              this.$emit("onDownload", false);
            } else {
              item.msg = "下载失败，网络异常";
              item.loading = false;
              item.error = true;
              this.$emit("onSureDown", true);
              this.$emit("onDownload", false);
              this.$EventBus.$emit("nextId", index); //下一个ID
            }
          });
      } else {
        //如果判断
        this.$EventBus.$emit("nextId", index); //下一个ID
      }
    },
    checkStatus(checkdata, index) {
      const checkTimer = setInterval(() => {
        check(checkdata)
          .then((res) => {
            let item = this.tableData[index];
            if (res.status == 0) {
              //完成下载
              item.videoDisabled = true;
              item.disabled = true;
              item.complete = true;
              item.loading = false;
              item.downurl = res.downloadUrl.replace("httpss", "https");
              item.downurl2 = res.downloadUrl2.replace("httpss", "https");
              item.downurl3 = res.downloadUrl3.replace("httpss", "https");
              item.price = res.price;
              this.urlArray.push(item.downurl); //批量
              this.urlArray1.push(item.downurl2); //批量备用
              this.urlArray2.push(item.downurl3); //批量备用
              this.$EventBus.$emit("nextId", index); //下一个ID
            } else if (res.status == 1) {
              //继续查询--不能一直查
              this.rightTimes = this.rightTimes + 1;
              if (this.rightTimes >= 25) {
                let item = this.tableData[index];
                item.complete = false;
                item.loading = false;
                item.error = true;
                if (
                  item.webType === "shutterstock" ||
                  item.webType === "shutterstock_V2"
                ) {
                  item.msg = "渠道下载失败，可以点击下方按钮使用V3渠道进行下载";
                  item.showButton = true;
                } else {
                  item.msg = "下载失败，请重新尝试";
                  item.showButton = false;
                }
                this.$EventBus.$emit("nextId", index); //下一个ID
              }
            } else {
              item.complete = false;
              item.loading = false;
              item.error = true;
              if (
                item.webType === "shutterstock" ||
                item.webType === "shutterstock_V2"
              ) {
                item.msg = "渠道下载失败，可以点击下方按钮使用V3渠道进行下载";
                item.showButton = true;
              } else {
                item.msg = "下载失败，请重新尝试";
                item.showButton = false;
              }
              this.$EventBus.$emit("nextId", index); //下一个ID
            }
          })
          .catch((err) => {
            console.log(err);
            let item = this.tableData[index];
            if (err.indexOf("账户余额") > -1) {
              item.msg = "下载失败，余额不足";
              item.loading = false;
              item.error = true;
              this.$emit("onSureDown", true);
              this.$emit("onDownload", false);
              this.$emit("clearTimer");
            } else {
              this.errorTimes = this.errorTimes + 1;
              if (this.errorTimes >= 3) {
                let item = this.tableData[index];
                item.complete = false;
                item.loading = false;
                item.error = true;
                if (
                  item.webType === "shutterstock" ||
                  item.webType === "shutterstock_V2"
                ) {
                  item.msg = "渠道下载失败，可以点击下方按钮使用V3渠道进行下载";
                  item.showButton = true;
                } else {
                  item.msg = "下载失败，请重新尝试";
                  item.showButton = false;
                }
                this.$EventBus.$emit("nextId", index); //下一个ID
              }
            }
          });
      }, 4000);

      this.$once("hook:beforeDestroy", () => {
        clearInterval(checkTimer);
      });
      this.$once("clearTimer", () => {
        clearInterval(checkTimer);
      });
    },
    //全部下载
    allDownload() {
      this.popSuccess();
    },
    //批量下载
    batchDownload() {
      for (var g = 0; g < this.urlArray.length; g++) {
        const url = this.urlArray[g];
        window.open(url, "_blank");
      }
    },
    //批量下载
    batchDownload1() {
      for (var g = 0; g < this.urlArray1.length; g++) {
        const url = this.urlArray1[g];
        window.open(url, "_blank");
      }
    },
    //批量下载
    batchDownload2() {
      for (var g = 0; g < this.urlArray2.length; g++) {
        const url = this.urlArray2[g];
        window.open(url, "_blank");
      }
    },
    batchCopy(e) {
      var text = "";
      for (var g = 0; g < this.urlArray.length; g++) {
        text += this.urlArray[g].replace("https", "http") + "\n";
      }
      const clipboard = new Clipboard(e.target, { text: () => text });
      clipboard.on("success", (e) => {
        message.success({
          message: "复制成功",
          duration: 4000,
          offset: 80,
        });
        // 释放内存
        clipboard.off("error");
        clipboard.off("success");
        clipboard.destroy();
      });
      clipboard.on("error", (e) => {
        message.success({
          message: "复制失败，请重试",
          duration: 4000,
          offset: 80,
        });
        // 释放内存
        clipboard.off("error");
        clipboard.off("success");
        clipboard.destroy();
      });
      clipboard.onClick(e);
    },
    batchCopy1(e) {
      var text = "";
      for (var g = 0; g < this.urlArray1.length; g++) {
        text += this.urlArray1[g].replace("https", "http") + "\n";
      }
      const clipboard = new Clipboard(e.target, { text: () => text });
      clipboard.on("success", (e) => {
        message.success({
          message: "复制成功",
          duration: 4000,
          offset: 80,
        });
        // 释放内存
        clipboard.off("error");
        clipboard.off("success");
        clipboard.destroy();
      });
      clipboard.on("error", (e) => {
        message.success({
          message: "复制失败，请重试",
          duration: 4000,
          offset: 80,
        });
        // 释放内存
        clipboard.off("error");
        clipboard.off("success");
        clipboard.destroy();
      });
      clipboard.onClick(e);
    },
    batchCopy2(e) {
      var text = "";
      for (var g = 0; g < this.urlArray2.length; g++) {
        text += this.urlArray2[g].replace("https", "http") + "\n";
      }
      const clipboard = new Clipboard(e.target, { text: () => text });
      clipboard.on("success", (e) => {
        message.success({
          message: "复制成功",
          duration: 4000,
          offset: 80,
        });
        // 释放内存
        clipboard.off("error");
        clipboard.off("success");
        clipboard.destroy();
      });
      clipboard.on("error", (e) => {
        message.success({
          message: "复制失败，请重试",
          duration: 4000,
          offset: 80,
        });
        // 释放内存
        clipboard.off("error");
        clipboard.off("success");
        clipboard.destroy();
      });
      clipboard.onClick(e);
    },
    deleteRow(index, row) {
      row.splice(index, 1);
    },
    changePrice(index, row, label, val) {
      row[index].videoLabel = label;
      row[index].videoValue = val;
      console.log(row[index].webType);
      if (row[index].webType.indexOf("vistockphoto") != -1) {
        switch (val) {
          case 0:
            row[index].webType = "vistockphoto";
            break;
          case 1:
            row[index].webType = "vistockphotofk";
            break;
        }
      } else {
        switch (val) {
          case 0:
            row[index].isChange = false;
            row[index].webType = "video";
            if (row[index].isBuy) {
              row[index].msg = "免费";
            } else {
              row[index].msg = "";
            }
            break;
          case 1:
            row[index].isChange = true;
            row[index].webType = "videofk";
            if (row[index].isBuy1) {
              row[index].msg = "免费";
            } else {
              row[index].msg = "";
            }
            break;
          case 2:
            row[index].isChange = false;
            row[index].webType = "videoyx";
            if (row[index].isBuy) {
              row[index].msg = "免费";
            } else {
              row[index].msg = "";
            }
            break;
          case 3:
            row[index].isChange = true;
            row[index].webType = "videoyxfk";
            if (row[index].isBuy1) {
              row[index].msg = "免费";
            } else {
              row[index].msg = "";
            }
            break;
        }
      }
      row[index].price = row[index].priceList[val].price;
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
    },
  },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.el-image {
  margin-right: 10px;
  float: left;
  width: 60px;
  height: 60px;
  border-radius: 4px;
}
//进度条
.processBar {
  width: 240px;
  height: 7px;
  border: 1px solid #0059ca;
  border-radius: 2px;
  animation: progress-bar-stripes 2s linear infinite;
  background-color: #0773fc;
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-size: 20px 20px;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  opacity: 1;
}
@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 40px 0;
  }
  to {
    background-position: 0 0;
  }
}

@keyframes progress-bar-stripes {
  from {
    background-position: 40px 0;
  }
  to {
    background-position: 0 0;
  }
}

.content {
  text-align: center;
}

.table {
  .el-table__body {
    img {
      margin-right: 10px;
      float: left;
      width: 60px;
      height: 60px;
      border-radius: 4px;
    }
    .fr {
      .code {
        margin-top: 5px;
        margin-bottom: 5px;
        font-size: 14px;
        color: #578ade;
        .label {
          font-size: 16px;
          color: #43424b;
        }
      }
      .size {
        padding-left: 10px;
        font-size: 10px !important;
      }
    }
    .el-button--mini.is-round {
      padding: 4px 10px;
      span {
        font-size: 10px;
      }
    }
    .price {
      .font-blue {
        color: #0773fc;
      }
      .font-red {
        color: #f56c6c;
      }
      .font-green {
        color: #67c23a;
      }
    }
  }
}
.btn {
  margin: 20px 0 100px;
  .el-button {
    margin-bottom: 20px;
    margin-left: 0;
    padding: 15px 20px;
    width: 100%;
    font-size: 16px;
    color: #0773fc;
    background: #fff;
    border-color: #0773fc;
    outline: none;
    i {
      font-size: 16px;
    }
  }
  .el-button--primary.is-plain:hover {
    background: #0773fc;
    border-color: #0773fc;
    color: #ffffff;
  }
  .el-button--success.is-plain:hover {
    background: #00cc66;
    border-color: #00cc66;
    color: #ffffff;
  }
  .el-button--info.is-plain:hover {
    background: #ff9900;
    border-color: #ff9900;
    color: #ffffff;
  }
  .el-button--danger.is-plain:hover {
    background: #ff3300;
    border-color: #ff3300;
    color: #ffffff;
  }
}

.btn2 {
  margin: 20px 0 100px;
  .el-button {
    margin-bottom: 20px;
    margin-left: 0;
    padding: 15px 20px;
    width: 49.5%;
    font-size: 16px;
    color: #0773fc;
    background: #fff;
    border-color: #0773fc;
    outline: none;
    i {
      font-size: 16px;
    }
  }
  .el-button--primary.is-plain:hover {
    background: #0773fc;
    border-color: #0773fc;
    color: #ffffff;
  }
  .el-button--success.is-plain:hover {
    background: #00cc66;
    border-color: #00cc66;
    color: #ffffff;
  }
  .el-button--info.is-plain:hover {
    background: #ff9900;
    border-color: #ff9900;
    color: #ffffff;
  }
  .el-button--danger.is-plain:hover {
    background: #ff3300;
    border-color: #ff3300;
    color: #ffffff;
  }
}

.el-pagination {
  margin: 20px 0 30px;
}
</style>
