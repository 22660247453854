<template>
  <div>
    <Tab
      :tablist="list"
      @change="changeTab"
      @constDetail="openConstDetailPop"
      @accountDetail="openAccountDetailPop"
      @serverDialog="openServerDialog"
      :tabCurrent="current"
    />
    <Content
      v-for="(item, index) in list"
      v-if="index == current"
      :key="index"
      :tabContent="item"
      :isSearching="isSearching"
      @search="searchId"
    />
    <Table
      :data="tableDatas"
      :sureflag="isSureDownload"
      :downflag="isDownloading"
      :clearflag="isClearFlag"
      :netError="isNetError"
      v-if="showTable"
      v-loading="tableLoading"
      @noBanlance="noBanlances"
      @onDownload="downloadId"
      @onSureDown="changeDown"
      @chanegV3="changeTab"
    />
    <Dialog
      :dialogVisible="dialogTableVisible"
      @closeDialog="closeConstDetailPop"
    />
    <Accountdialog
      :dialogVisible="accountDialogTableVisible"
      @closeDialog="closeAccountDetailPop"
    />
    <Serverdialog
      :dVisible="serverDialogVisible"
      @closeDialogs="closeServerDialod"
    />
  </div>
</template>

<script>
import Tab from "./module/tab.vue";
import Table from "./module/table.vue";
import Content from "./module/content.vue";
import Dialog from "./module/dialog.vue";
import { searchImage, getShutterStatus } from "../../api/downLoad";
import { message } from "@/utils/resetMessage";
import Accountdialog from "./module/accountdialog";
import Serverdialog from "./module/serverdialog";
//import crypto from "crypto";

export default {
  components: {
    Accountdialog,
    Tab,
    Table,
    Content,
    Dialog,
    Serverdialog,
  },
  data() {
    return {
      getAll_status: 0, //状态
      isLoggedIn: false,
      current: 0,
      webType: "shutterstock_V2",
      list: [
        {
          title: "SS",
          subTitle: "SS",
          titleState: "success",
          tip: "",
          label: null,
          webType: "shutterstock_V2",
          isPop: false,
          icon: "",
          status: true,
        },
        {
          title: "SS_V3",
          subTitle: "S/H",
          titleState: "success",
          tip:
            "<ul><li>V3为备用渠道，成本比较高，价格比较贵，提供给急用的客户使用。</li> <li>最近因为官网问题，导致很多下载不稳定，非常抱歉，我们在努力修复这个问题。</li> </ul>",
          label: null,
          isPop: false,
          webType: "shutterstock_V3",
          icon: "",
          status: true,
        },
        {
          title: "AdobeStock",
          subTitle: "",
          titleState: "success",
          label: null,
          isPop: false,
          webType: "adobestock",
          icon: require("@/assets/images/download/ic8_8.png"),
          status: true,
        },
        {
          title: "iStockphoto",
          subTitle: "",
          titleState: "success",
          label: null,
          webType: "istockphoto",
          isPop: false,
          icon: require("@/assets/images/download/ic5_5.png"),
          status: true,
        },
        {
          title: "iStockphoto视频",
          subTitle: "",
          titleState: "success",
          tip: "",
          label: null,
          isPop: false,
          webType: "vistockphoto",
          icon: require("@/assets/images/download/ic5_5.png"),
          status: true,
        },
        {
          title: "Depositphotos",
          subTitle: "",
          titleState: "success",
          label: null,
          isPop: false,
          webType: "depositphotos",
          isPop: false,
          icon: require("@/assets/images/download/ic2_2.png"),
          status: true,
        },
        {
          title: "Dreamstime",
          subTitle: "",
          titleState: "success",
          label: null,
          isPop: false,
          webType: "dreamstime",
          icon: require("@/assets/images/download/ic6_6.png"),
          status: true,
        },
        {
          title: "123rf",
          subTitle: "",
          titleState: "success",
          label: null,
          isPop: false,
          webType: "rfoto",
          icon: require("@/assets/images/download/ic3_3.png"),
          status: true,
        },
        {
          title: "vectorstock",
          subTitle: "",
          titleState: "success",
          label: null,
          webType: "vectorstock",
          isPop: false,
          icon: require("@/assets/images/download/ic11_11.png"),
          status: true,
        },
        {
          title: "freepik",
          subTitle: "",
          tip:
            "<ul><li>Freepik请复制图片编号下载，编号为网址栏.htm前面的数字</ul></li>",
          titleState: "success",
          label: null,
          webType: "freepik",
          isPop: false,
          icon: require("@/assets/images/download/ic10_10.png"),
          status: true,
        },
        {
          title: "Alamy",
          subTitle: "",
          tip:
            "<ul><li>Alamy请复制网站页面上的图片ID下载，如：Image ID: D2I95C</li><li>Alamy仅提供JPG格式，无法提供矢量格式，请按需下载</li></ul>",
          titleState: "success",
          label: null,
          isPop: false,
          webType: "alamy",
          icon: require("@/assets/images/download/alamy.png"),
          status: true,
        },
        {
          title: "Pixelsquid",
          subTitle: "",
          tip:
            "<ul><li>pixelsquid请复制网站页面上的ID下载，如：https://www.pixelsquid.com/png/retro-car-white-2337168235838838579，则2337168235838838579是素材ID，输入素材ID进行下载</li></ul>",
          titleState: "success",
          label: null,
          webType: "pixelsquid",
          isPop: false,
          icon: require("@/assets/images/download/pixelsquid.png"),
          status: true,
        },
        {
          title: "Veer",
          subTitle: "",
          tip:
            "<ul><li>Veer素材仅提供【照片】和【插画】板块的素材下载，其他素材板块暂时不支持下载，如有需要可以联系上方客服咨询</li><li>如素材提示下架，可以联系上方客服咨询</li></ul>",
          titleState: "success",
          label: null,
          webType: "veer",
          isPop: false,
          icon: require("@/assets/images/download/veer.png"),
          status: true,
        },
        {
          title: "SS视频",
          subTitle: "SS视频",
          titleState: "success",
          tip:
            "SS/Hai视频下载目前仅支持HD普通视频下载，如需严选或4K视频请联系客服下载",
          label: null,
          webType: "video",
          icon: "",
          status: true,
        },
      ],
      tableDatas: [],
      showTable: false, //是否显示表格
      tableLoading: false, //加载数据效果
      dialogTableVisible: false, //费用明细弹窗
      accountDialogTableVisible: false, //余额弹窗
      serverDialogVisible: false, //客服弹窗
      form: {
        webType: "",
        imageID: "",
      },
      dataForm: {
        img: "", //缩略图
        id: "", //图片ID
        imgSize: "", //图片大小
        complete: false, //操作状态为按钮
        loading: false, //操作状态为加载
        saleType: "", //默认为空
        price: 0, //图片价格
        priceList: [], //视频价格
        videoType: 0, //视频格式
        isfk: false, //是否4k
        isyx: false, //是否严选
        orgPrice: "", //原价
        balance: "", //用户余额
        value: 4, //图片类型默认值
        disabled: false, //是否可选
        videoValue: 0, //视频类型默认值
        videoLabel: "", //视频标签
        videoDisabled: false, //是否可选
        videoOptions: [], //视频类型
        options: [], //图片类型
        webType: this.webType, //网站类型
        downurl: "", //主下载链接
        downurl2: "", //备用下载链接
        type: true, //搜索ID是否正常
        showType: true, //展示类型
        isBuy: false, //是否购买过
        msg: "", //提示消息
        error: false, //下载是否出错
        isChange: false, //默认没有点击切换
      },
      timer: null, //定时器
      isSearching: false, // 是否在搜索
      searchCount: 0, //搜索数量
      isDownloading: false, // 是否正在下载
      isSureDownload: false, // 是否确认下载
      isClearFlag: false, //是否需要清除Url
      isNetError: false, //是否网络通畅标记
    };
  },
  mounted() {
    this.isLoggedIn =
      (this.$store.state.user.token !== null ||
        this.$store.state.user.token !== "") &&
      this.$store.state.user.loginNameType != null
        ? true
        : false;

    this.getStatus();
    let userName = "",
      userEmail = "";
    if (this.$store.state.common.userInfo.mobile) {
      userName = this.$store.state.common.userInfo.mobile;
    }
    if (this.$store.state.common.userInfo.mail) {
      userEmail = this.$store.state.common.userInfo.mail;
    }

    userName = userName ? userName : userEmail; //为空则赋一样的值
    userEmail = userEmail ? userEmail : userName + "@taogetu.com"; //为空需要加邮箱后缀，否则无法修改用户
  },
  beforeDestroy() {
    clearTimeout(this.timer);
    this.timer = null;
  },
  methods: {
    //获取tab列表
    getStatus() {
      getShutterStatus()
        .then((res) => {
          let data = JSON.parse(res);
          setTabStatus(data.adobestock, "adobestock");
          setTabStatus(data.istockphoto, "istockphoto");
          setTabStatus(data.dreamstime, "dreamstime");
          setTabStatus(data.vistockphoto, "vistockphoto");
          setTabStatus(data[`123RF`], "123RF");
          setTabStatus(data.vectorstock, "vectorstock");
          setTabStatus(data.Alamy, "alamy");
          setTabStatus(data.pixelsquid, "pixelsquid");
        })
        .catch(() => {});

      this.timer = setTimeout(() => {
        getShutterStatus()
          .then((res) => {
            let data = JSON.parse(res);
            setTabStatus(data.adobestock, "adobestock");
            setTabStatus(data.istockphoto, "istockphoto");
            setTabStatus(data.dreamstime, "dreamstime");
            setTabStatus(data.vistockphoto, "vistockphoto");
            setTabStatus(data[`123RF`], "123RF");
            setTabStatus(data.vectorstock, "vectorstock");
            setTabStatus(data.Alamy, "alamy");
            setTabStatus(data.pixelsquid, "pixelsquid");
          })
          .catch(() => {});
      }, 60000);
    },
    setTabStatus(data, name) {
      try {
        this.list.forEach((item) => {
          if (item.webType == name) {
            if (data == "online") {
              item.status = true;
            } else {
              item.status = false;
            }
            throw new Error("End Loop");
          }
        });
      } catch (e) {}
    },
    //切换tab
    changeTab(index) {
      this.current = index;
      this.webType = this.list[this.current].webType;
      this.tableDatas = [];
      this.isSearching = false;
      this.isSureDownload = false; // 是否确认下载
      this.showTable = false; //是否显示表格
      this.tableLoading = false; //加载数据效果
      this.dialogTableVisible = false; //费用明细弹窗
      this.accountDialogTableVisible = false; //余额弹窗
    },
    //打开客服下载提示
    openServerDialog() {
      this.serverDialogVisible = true;
    },
    closeServerDialod() {
      this.serverDialogVisible = false;
    },
    //费用明细
    openConstDetailPop() {
      this.dialogTableVisible = true;
    },
    closeConstDetailPop() {
      this.dialogTableVisible = false;
    },
    //余额明细
    openAccountDetailPop() {
      this.accountDialogTableVisible = true;
    },
    closeAccountDetailPop() {
      this.accountDialogTableVisible = false;
    },
    //获取表格数据
    getTableDatas(result) {
      this.dataForm = {
        img: "", //缩略图
        id: "", //图片ID
        imgSize: "", //图片大小
        complete: false, //操作状态为按钮
        loading: false, //操作状态为加载
        saleType: "", //默认为空
        price: 0, //图片价格
        priceList: [], //视频价格
        videoType: 0, //视频格式
        isfk: false, //是否4k
        isyx: false, //是否严选
        orgPrice: "", //原价
        balance: "", //用户余额
        value: 4, //图片类型默认值
        disabled: true, //是否可选
        videoValue: 0, //视频类型默认值
        videoLabel: "", //视频标签
        videoDisabled: false, //是否可选
        videoOptions: [], //视频类型
        options: [], //图片类型
        webType: this.webType, //网站类型
        isVideo: false, //是否视频
        downurl: "", //主下载链接
        downurl2: "", //备用下载链接
        type: true, //正常
        showType: true, //是否显示类型
        msg: "", //提示消息
        isBuy: false, //是否购买过
        isBuy1: false, //是否购买过1
        isCos: false, //是否腾讯云
        errorIcon: "", //飞鸟 链接
        checking: false, //是否查状态
        isChange: false, //默认没有点击切换
        showButton: false, //是否显示按钮
      };
      //请求成功后赋值，并且去掉加载效果
      if (result.status == 0) {
        if (
          this.webType != "shutterstock" &&
          this.webType != "shutterstock_V2"
        ) {
          this.dataForm.img = result.iconUrl;
        } else {
          //替换链接
          if (result.iconUrl != null && result.iconUrl != "") {
            this.dataForm.img =
              "https://img.taogetu.com/preview260/" + result.recCode + ".jpg";
            this.dataForm.errorIcon = result.iconUrl.replace(
              "image.shutterstock.com",
              "img.gallerydownload.com"
            );
          }
        }

        this.dataForm.id = result.recCode;
        this.dataForm.price = result.price;
        this.dataForm.orgPrice = result.orgPrice;
        this.dataForm.isVideo = false;

        if (result.searchDTO.picType == 0) {
          //0为jpg
          this.dataForm.imgSize = result.detailJson[0].px;
          this.dataForm.value = 0;
          this.dataForm.options = [{ value: 0, label: "JPG" }];
        } else if (result.searchDTO.picType == 1) {
          //4为eps
          this.dataForm.value = 1;
          this.dataForm.options = [{ value: 1, label: "ait" }];
        } else if (result.searchDTO.picType == 2) {
          //4为eps
          this.dataForm.value = 2;
          this.dataForm.options = [{ value: 2, label: "indt" }];
        } else if (result.searchDTO.picType == 3) {
          //4为eps
          this.dataForm.value = 3;
          this.dataForm.options = [{ value: 3, label: "psdt" }];
        } else if (result.searchDTO.picType == 4) {
          //4为eps
          this.dataForm.value = 4;
          this.dataForm.options = [{ value: 4, label: "EPS" }];
        } else if (result.searchDTO.picType == 9) {
          this.dataForm.showType = false;
        } else if (result.searchDTO.picType == 10) {
          //10为eps
          this.dataForm.value = 10;
          this.dataForm.options = [{ value: 10, label: "svg" }];
        } else if (result.searchDTO.picType == 11) {
          //11为eps
          this.dataForm.value = 11;
          this.dataForm.options = [{ value: 11, label: "ai" }];
        } else if (result.searchDTO.picType == 12) {
          //11为eps
          this.dataForm.value = 12;
          this.dataForm.options = [{ value: 12, label: "mdl" }];
        }
        this.dataForm.isCos = result.detailJson[0].isCos;
        //设置是否购买
        this.dataForm.isBuy = result.detailJson[0].isBuy;
        this.dataForm.isBuy1 = result.detailJson[0].isBuy1;

        if (this.dataForm.isBuy) {
          this.dataForm.msg = "免费";
        }
        //视频搜索
        if (
          this.webType.indexOf("video") != -1 ||
          this.webType.indexOf("vedio") != -1 ||
          this.webType.indexOf("vistockphoto") != -1
        ) {
          this.dataForm.isVideo = true;
          this.dataForm.value = result.searchDTO.picType;
          this.dataForm.priceList = result.priceList;
          if (result.searchDTO.picType == 5) {
            this.dataForm.options = [{ value: 5, label: "MOV" }];
          } else {
            this.dataForm.options = [{ value: 6, label: "MP4" }];
          }
          this.dataForm.isyx = result.detailJson[0].yxflag;
          this.dataForm.isfk = result.detailJson[0].fkflag;
          this.dataForm.videoType = result.videoType;
          switch (this.dataForm.videoType) {
            case 0:
              this.dataForm.videoOptions = [{ value: 0, label: "HD" }];
              this.dataForm.videoLabel = "HD";
              if (this.webType.indexOf("vistockphoto") != -1) {
                this.dataForm.webType = "vistockphoto";
              } else {
                this.dataForm.webType = "video";
              }
              this.dataForm.videoValue = 0;
              break;
            case 1:
              this.dataForm.videoOptions = [
                { value: 0, label: "HD" },
                { value: 1, label: "4K" },
              ];
              this.dataForm.videoLabel = "HD";
              if (this.webType.indexOf("vistockphoto") != -1) {
                this.dataForm.webType = "vistockphoto";
              } else {
                this.dataForm.webType = "video";
              }
              this.dataForm.videoValue = 0;
              break;
            case 2:
              this.dataForm.videoOptions = [{ value: 2, label: "严选HD" }];
              this.dataForm.videoLabel = "严选HD";
              this.dataForm.webType = "videoyx";
              this.dataForm.videoValue = 2;
              this.dataForm.price = result.priceList[2].price;
              break;
            case 3:
              this.dataForm.videoOptions = [
                { value: 2, label: "严选HD" },
                { value: 3, label: "严选4K" },
              ];
              this.dataForm.videoLabel = "严选HD";
              this.dataForm.webType = "videoyx";
              this.dataForm.videoValue = 2;
              this.dataForm.price = result.priceList[2].price;
              break;
          }
        }
      } else {
        this.dataForm.id = result.recCode;
        this.dataForm.msg = result.msg;
        this.dataForm.type = false;
      }
      this.tableDatas.push(this.dataForm);
      this.tableLoading = false;
    },
    //下载ID
    downloadId(flag) {
      this.isSearching = flag;
      this.isDownloading = flag;
    },
    //改变下载状态
    changeDown(flag) {
      this.isSureDownload = flag;
    },
    //搜索ID
    async searchId(prams) {
      if (!this.isLoggedIn) {
        message.error({
          message: "您还没登录用户，请登录",
          showClose: true,
          duration: 4000,
          offset: 100,
        });
        return;
      }
      this.isSearching = true;
      this.isClearFlag = true;
      this.isDownloading = true;
      this.isSureDownload = false;
      this.tableDatas = [];
      this.showTable = true;
      this.tableLoading = true;
      var len = 0;
      let mblance = this.$store.state.common.userInfo.blance;
      //根据余额限制搜索数量
      if (mblance <= 50 && prams.length > 30) {
        //0-50元余额用户限制一次最多搜索30个
        message.error({
          message: "一次性最多只能搜索30个ID",
          showClose: true,
          duration: 4000,
          offset: 100,
        });
        this.downloadId(false);
        this.showTable = false;
        this.tableLoading = false; //加载数据效果
        return;
      } else if (mblance <= 100 && prams.length > 70) {
        //50-100元余额用户限制一次最多搜索70个
        message.error({
          message: "一次性最多只能搜索70个ID",
          showClose: true,
          duration: 4000,
          offset: 100,
        });
        this.downloadId(false);
        this.showTable = false;
        this.tableLoading = false; //加载数据效果
        return;
      } else {
        // 100元以上用户不作限制
      }

      while (len < prams.length) {
        let dataform = {
          webType: this.webType,
          imageID: prams[len],
        };
        try {
          let res = await searchImage(dataform);
          if (res.recCode != null && res.recCode != "") {
            this.isNetError = false;
            this.getTableDatas(res);
          } else {
            let res = await searchImage(dataform);
            if (res.recCode != null && res.recCode != "") {
              this.isNetError = false;
              this.getTableDatas(res);
            } else {
              this.isNetError = true;
              this.tableLoading = false;
              message.error({
                message: "网络异常，请重试",
                showClose: true,
                duration: 4000,
                offset: 100,
              });
            }
          }
        } catch (e) {
          this.isNetError = true;
          message.error({
            message: "网络异常，请重试",
            showClose: true,
            duration: 4000,
            offset: 100,
          });
        }
        this.tableDatas.length > 0
          ? (this.tableLoading = false)
          : (this.tableLoading = true);
        len++;
      }
      this.isSearching = false;
      this.isDownloading = false;
      this.isClearFlag = false;
      this.tableLoading = false;
    },
    noBanlances() {
      //余额不足
      this.tableDatas = [];
      this.isSureDownload = false;
    },
    async doSearchID(dataform) {
      try {
        let res = await searchImage(dataform);
        if (res.recCode != null && res.recCode != "") {
          this.isNetError = false;
          this.getTableDatas(res);
        } else {
          let res = await searchImage(dataform);
          if (res.recCode != null && res.recCode != "") {
            this.isNetError = false;
            this.getTableDatas(res);
          } else {
            this.isNetError = true;
            message.error({
              message: "网络异常，请重试",
              showClose: true,
              duration: 4000,
              offset: 100,
            });
          }
        }
      } catch (e) {
        let res = await searchImage(dataform);
        if (res.recCode != null && res.recCode != "") {
          this.isNetError = false;
          this.getTableDatas(res);
        } else {
          this.isNetError = true;
          message.error({
            message: "网络异常，请重试",
            showClose: true,
            duration: 4000,
            offset: 100,
          });
        }
      }
    },
  },
};
/*var tawks1,tawks0;
(function(){
  //console.log("test111","embed.tawk.to");
  //tawks1=document.createElement("script"),tawks0=document.getElementsByTagName("script")[0];
  //放到onMounted里面设置，不然没法设置用户名
  // tawks1.async=true;
  // tawks1.src='https://embed.tawk.to/5e883faf69e9320caac026bc/default';
  // tawks1.charset='UTF-8';
  // tawks1.setAttribute('crossorigin','*');
  //tawks0.parentNode.insertBefore(tawks1,tawks0);
})();*/
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
::v-deep li {
  list-style: initial !important;
}
::v-deep ul {
  padding: 0 10px;
}
</style>
